import React from "react";

import Ready from "../ready/ready";
import LockdownFunctions from "../product/lockdown_functions";
import Subscribe from "../subscribe/subscribe_2";
import TopBanner from "../top_banner/product_top_banner";
import Value from "../value/value";
import { useTranslation } from 'react-i18next';

const ProductEvaluation = () => {
  const { t } = useTranslation();
  const LockdownFunctionData = [
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/tools_icon_with_bg.svg",
      title: `${t('decision_making_skills')}`,
      description: `${t('ability_of_the_candidate_to_conduct')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/brain_bulb_icon_with_bg.svg",
      title: `${t('solving_complex_problems')}`,
      description: `${t('mastery_of_basic_arithmetic')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/puzzle_icon_with_bg.svg",
      title: `${t('digital_and_spatial_logic')}`,
      description:
        `${t('analytical_capacity_of_the_candidate')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/head_with_setting_icon_inside_with_bg.svg",
      title: `${t('critical_thinking')}`,
      description:
        `${t('ability_of_the_candidate_to_enter')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/man_with_puzzle_in_bg_icon_with_bg.svg",
      title: `${t('editorial_capacity')}`,
      description:
        `${t('ability_of_the_candidate_to_distinguish')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/scope_with_alert_icon_with_bg.svg",
      title: `${t('contemporary_issues')}`,
      description:
      `${t('knowledge_of_the_candidate_on_the_major')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/bank_icon_with_bg.svg",
      title: `${t('general_culture')}`,
      description:
        `${t('knowledge_of_the_candidate_on_events_works')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/globe_in_message_icon_with_bg.svg",
      title: `${t('languages')}`,
      description:
        `${t('ability_to_understand_and_interpret_a_text')}`,
    },
    {
      iconSrc: "https://d2lxkizvrhu4im.cloudfront.net/icons/star_icon_with_bg.svg",
      title: `${t('specific_skills')}`,
      description:
        `${t('candidate_skills_relating_to_the_training')}`,
    },
  ];
  return (
    <div className={`conatiner font-sans`}>
      <TopBanner
        pageTitle={t('educational_engineering')}
        title={t('innovative_and_high_scientific_quality_tests')}
        description={t('in_partnership_with_editions_ellipses')}
        imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/product_top_banner_contenu.svg"
        leftButton={{
          text: `${t("free_trials")}`,
          href: "/free-trial",
        }}
        rightButton={{
          text: `Demo >`,
          href: "/demo",
        }}
      />

      {/* sub Container */}
      <div className={`mx-auto w-11/12 lg:w-5/6 `}>
        <LockdownFunctions
          title={t('tailor_made_assessments')}
          subTitle={t('with_mereos_choose_the_skills')}
          Data={LockdownFunctionData}
        />
        <div className="my-20">
          <Value
            page={"contenu"}
          />
        </div>
        {/* Ready */}
        <div className="my-20">
          <Ready />
        </div>
        {/* <div className="my-20">
          <Analytics />
        </div> */}
      </div>
      <Subscribe />
    </div>
  );
};

export default ProductEvaluation;
