import * as React from "react";

import Layout from "../components/layout/layout";
import ProductEvaluation from "../components/product-page/product_evaluation";
import Seo from "../components/seo";
import { useTranslation } from 'react-i18next';

const ProductContenu = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title={t('educational_engineering')}
        description={`${t('move_from_idea_to_action_with')}`}
      />
      <Layout>
        <ProductEvaluation />  
      </Layout>
    </>
  )
}

export default ProductContenu
