import React from "react";
import { useTranslation } from 'react-i18next';

const Value = ({ page }) => {
  const {t} = useTranslation();
  return (
    <div>
      <div className={`w-full h-full  mx-auto my-3 text-center space-y-8`}>
        <div className="my-3 text-center space-y-8 md:mb-20">
          <div className="text-3xl md:text-5xl font-bold w-2/3 mx-auto">
            {t('a_major_player_in_applied_psychotechnology')}
          </div>
          <div className=" text-sm w-11/12 md:w-2/3 mx-auto md:text-xl text-gray-500">
            {t('mereos_counts_among_its_authors')}
          </div>
          {
            page === "contenu" ? null : (
              <div className="mx-2 lg:mx-auto">
                <img
                  alt="values img"
                  src="https://d2lxkizvrhu4im.cloudfront.net/images/value_img_2.svg"
                />
              </div>
            )
          }
        </div>

        <div className="flex flex-col md:flex-row justify-between ">
          <div className=" flex flex-row md:flex-col w-full mx-2 ">
            <div className="md:mx-auto">
              <img
                alt="values img"
                src="https://d2lxkizvrhu4im.cloudfront.net/icons/dart_icon_with_bg.svg"
              />
            </div>
            <div className="flex flex-col w-full text-left md:text-center ml-5 md:ml-0">
              <div className="md:text-xl flex flex-row justify-start md:justify-center font-bold ">
                {t('loyalty')}
              </div>
              <div className="text-sm md:text-lg font-medium text-gray-400 w-11/12 md:w-5/6 flex flex-row justify-start md:justify-center md:mx-auto">
                {t('estimation_of_the_degree')}
              </div>
            </div>
          </div>

          <div className=" flex flex-row md:flex-col w-full mx-2 ">
            <div className="md:mx-auto">
              <img
                alt="values img"
                src="https://d2lxkizvrhu4im.cloudfront.net/icons/tick_icon_with_bg.svg"
              />
            </div>
            <div className="flex flex-col w-full text-left md:text-center ml-5 md:ml-0">
              <div className="md:text-xl flex flex-row justify-start md:justify-center font-bold ">
                {t('validity')}
              </div>
              <div className="text-sm md:text-lg font-medium text-gray-400 w-11/12 md:w-5/6 flex flex-row justify-start md:justify-center md:mx-auto">
                {t('ability_for_a_test_to_measure')}
              </div>
            </div>
          </div>

          <div className=" flex flex-row md:flex-col  mx-2  w-full ">
            <div className="md:mx-auto">
              <img
                alt="values img"
                src="https://d2lxkizvrhu4im.cloudfront.net/icons/crown_icon_with_bg.svg"
              />
            </div>
            <div className="flex flex-col w-full text-left md:text-center ml-5 md:ml-0">
              <div className=" md:text-xlflex flex-row justify-start md:justify-center font-bold ">
                {t('sensitivity')}
              </div>
              <div className="text-sm md:text-lg font-medium text-gray-400 w-11/12 md:w-5/6 flex flex-row justify-start md:justify-center md:mx-auto">
                {t('ability_to_distinguish_candidates')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Value;
